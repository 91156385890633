import React, { Component } from 'react'
import * as styles from './back-button.module.css'
import classNames from 'classnames'
import Arrow from '../icons/Arrow'

class BackButton extends Component {
  render() {
    const { className, children } = this.props

    const buttonClasses = {
      [styles.button]: true,
      [className]: className,
    }

    return (
      <button className={classNames(buttonClasses)}>
        <Arrow /> {children}
      </button>
    )
  }
}

export default BackButton
