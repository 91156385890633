import React from 'react'
import { Link } from 'gatsby'
import SEO from '../seo'
import Layout from '../layout'
import Callout from '../../components/callout'
import BackButton from '../backButton'
import { getTextCopy } from '../../helpers'
import * as styles from './case-study-layout.module.css'
import useCaseStudyTemplateContent from '../../hooks/useCaseStudyTemplateContent'

const CaseStudyLayout = ({ caseStudy, children }) => {
  const caseStudyTemplateContent = useCaseStudyTemplateContent()
  const {
    seoImage,
    pageSlug,
    content,
    callout,
  } = caseStudyTemplateContent.caseStudyPage
  const { workPageSlug } = caseStudyTemplateContent.workPage
  const { slug, seoTitle, excerpt } = caseStudy
  const fullSlug = pageSlug.replace(`{{slug}}`, slug)

  return (
    <>
      <SEO
        title={seoTitle}
        description={excerpt}
        imageUrl={seoImage.file.url}
        urlPath={fullSlug}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <div className={styles.caseStudyWrapper}>
          <div className={styles.backButtonContainer}>
            <Link to={workPageSlug}>
              <BackButton>
                {getTextCopy(content, 'page.caseStudy.backButton')}
              </BackButton>
            </Link>
          </div>
          <div className={styles.caseStudy}>{children}</div>
        </div>
      </Layout>
    </>
  )
}
export default CaseStudyLayout
