import { graphql, useStaticQuery } from 'gatsby'

const useCaseStudyTemplateContent = () => {
  const caseStudyTemplateContent = useStaticQuery(graphql`
    query caseStudyTemplateQuery {
      caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
        ...pageFields
      }
      workPage: contentfulPage(name: { eq: "page.work" }) {
        workPageSlug: slug
      }
    }
  `)

  return caseStudyTemplateContent
}

export default useCaseStudyTemplateContent
