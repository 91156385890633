import React, { useEffect, useRef } from 'react'
import CaseStudyLayout from '../../../components/caseStudyLayout'
import * as styles from './styles.module.css'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import lbTablet from './img/LB-tablet.png'
import birdLogo from './img/little-bird-logo.png'
import phoneAndroid from './img/littlebird-phone-android.png'
import phoneOff from './img/littlebird-phone-off.png'
import phone from './img/littlebird-phone.png'
import screens from './img/screens.png'
import tileAppleAndroid from './img/tile-apple-android.png'
import tileIot from './img/tile-iot.png'
import tileReact from './img/tile-react.png'
import tileWebrtc from './img/tile-webrtc.png'
import tileZwave from './img/tile-zwave.png'
import toggleAndroid from './img/toggle-android.png'
import toggleIos from './img/toggle-ios.png'

const Littlebird = ({ data }) => {
  const overlayRef = useRef(null)
  const phone1Ref = useRef(null)
  const phone2Ref = useRef(null)
  const triggerRef = useRef(null)
  const toggleIosRef = useRef(null)
  const toggleAndroidRef = useRef(null)
  const iosRef = useRef(null)
  const androidRef = useRef(null)

  useEffect(() => {
    triggerRef.current.addEventListener('click', function(e) {
      e.preventDefault()
      overlayRef.current.classList.toggle(styles.on)
      phone1Ref.current.classList.toggle(styles.on)
      phone2Ref.current.classList.toggle(styles.on)
    })

    toggleIosRef.current.addEventListener('click', function(e) {
      e.preventDefault()
      toggleIosRef.current.classList.add(styles.on)
      iosRef.current.classList.add(styles.on)
      toggleAndroidRef.current.classList.remove(styles.on)
      androidRef.current.classList.remove(styles.on)
    })

    toggleAndroidRef.current.addEventListener('click', function(e) {
      e.preventDefault()
      toggleAndroidRef.current.classList.add(styles.on)
      androidRef.current.classList.add(styles.on)
      toggleIosRef.current.classList.remove(styles.on)
      iosRef.current.classList.remove(styles.on)
    })
  }, [])
  return (
    <>
      <CaseStudyLayout {...data}>
        <Helmet bodyAttributes={{ class: styles.littlebirdBody }} />
        <div className={styles.littlebird}>
          <span className={styles.overlay} id="overlay" ref={overlayRef}></span>
          <hr style={{ height: '32px' }} />
          <section className={styles.intro}>
            <span className={`${styles.verticalLine} ${styles.pos1}`}></span>
            <span className={`${styles.horizontalLine} ${styles.pos2}`}></span>
            <span className={`${styles.verticalLine} ${styles.pos3}`}></span>
            <div className={styles.intro__text}>
              <h2>Client</h2>
              <figure>
                <img alt="" src={birdLogo} className={styles.logo} />
              </figure>
              <h1>Smarter, Safer Apartment Living</h1>
              <h2>Challenge</h2>
              <p className={`${styles.sans} ${styles.small}`}>
                Build a simple, intuitive home automation app for Property
                Managers to control and connect with their property and for
                apartment-dwellers to be able to control their unit and connect
                with their community.
              </p>
            </div>
            <div className={styles.intro__img}>
              <figure>
                <img
                  alt=""
                  src={phone}
                  className={`${styles.img} ${styles.on}`}
                  id="phone1"
                  ref={phone1Ref}
                />
                <img
                  alt=""
                  src={phoneOff}
                  className={styles.img}
                  id="phone2"
                  ref={phone2Ref}
                />
                <div className={styles.pulse}></div>
                <div className={`${styles.tooltip} ${styles.small}`}>
                  Try me!
                </div>
                <div
                  ref={triggerRef}
                  id="trigger"
                  className={styles.trigger}
                ></div>
              </figure>
            </div>
          </section>
          <section className={styles.quote}>
            <p className={styles.large}>
              Synapse turned our early product concepts into a fully fleshed-out
              app and platform that enabled us to leap past our competitors in
              features, quality, and user experience. We've continued to work
              with them long after the initial launch because of the sharp
              talent, high standards, and refined process they bring to the
              table.
            </p>
            <p className={styles.small}>
              James Archer, Chief Product Officer @ LittleBird
            </p>
            <section className={styles.icons}>
              <img alt="" src={tileReact} />
              <img alt="" src={tileIot} />
              <img alt="" src={tileZwave} />
              <img alt="" src={tileWebrtc} />
              <img alt="" src={tileAppleAndroid} />
            </section>
          </section>
          <section className={styles.wow}>
            <span className={`${styles.verticalLine} ${styles.pos4}`}></span>
            <img alt="" src={screens} />
            <p className={styles.large}>
              Synapse Studios worked hand-in-hand with LittleBird to craft a
              comprehensive mobile app that allows tenants to control their door
              locks, control access to unlock codes, home lighting, and
              thermostat from anywhere, while bringing a community element
              directly into the app.
            </p>
          </section>
          <section className={styles.mocks}>
            <span className={`${styles.horizontalLine} ${styles.pos5}`}></span>
            <article className={styles.iphone}>
              <p className={`${styles.sans} ${styles.small}`}>Resident</p>
              <div className={styles.phoneToggle}>
                <img
                  alt=""
                  src={toggleIos}
                  className={styles.on}
                  id="toggle-ios"
                  ref={toggleIosRef}
                />
                <img
                  alt=""
                  src={toggleAndroid}
                  id="toggle-android"
                  ref={toggleAndroidRef}
                />
              </div>
              <p className={`${styles.small} ${styles.light}`}>
                Developing in React Native, we are able to work faster and build
                and support both the iOS and Android app from one common code
                base.
              </p>
              <figure className={styles.phoneSwitch}>
                <img
                  alt=""
                  src={phone}
                  className={`${styles.phoneIos} ${styles.on}`}
                  id="phone-ios"
                  ref={iosRef}
                />
                <img
                  alt=""
                  src={phoneAndroid}
                  className={styles.phoneAndroid}
                  id={styles.phoneAndroid}
                  ref={androidRef}
                />
              </figure>
            </article>
            <article className={styles.tablet}>
              <p className={`${styles.sans} ${styles.small}`}>
                Property Manager
              </p>
              <p className={styles.small}>
                We also crafted the backend administrative system, allowing
                property staff to remotely manage their vacant units’ utilities,
                reducing costs associated with paying heating and cooling bills
                for vacant units, along with being able to welcome new tenants
                to their future homes.
              </p>
              <figure>
                <img alt="" src={lbTablet} />
              </figure>
            </article>
          </section>
        </div>
      </CaseStudyLayout>
    </>
  )
}

export default Littlebird

export const query = graphql`
  query littlebirdsCaseStudyQuery {
    caseStudy: contentfulCaseStudy(slug: { eq: "littlebird" }) {
      seoTitle
      slug
      title
      excerpt
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      ...pageFields
    }
    workPage: contentfulPage(name: { eq: "page.work" }) {
      workPageSlug: slug
    }
  }
`
